import React, { useContext } from 'react'
import PropTypes from 'prop-types'
import { graphql } from 'gatsby'
import LocaleContext from '@context/LocaleContext'
import Layout from '@components/Layout'
import SEO from '@components/seo'
import PageTitle from '@components/PageTitle'
import RichText from '@components/RichText'
import ButtonPrimary from '@components/ButtonPrimary'
import localize from '@components/localize';

export const query = graphql`
  query {
    sanityContactPage {
      contentTitle {
        en
        jp
      }
      title {
        en
        jp
      }
      paragraph {
        _rawJp
        _rawEn
      }
      email
    }
  }
`

const Contact = ({
  data: { sanityContactPage },
  pageContext: { title, description, metaImage }
}) => {
  const { code } = useContext(LocaleContext)
  return (
    <Layout>
      <SEO title={title} description={description} image={metaImage?.asset.url} />
      <PageTitle>
        {title}
      </PageTitle>
      <div className="bg-white">
        <div className="wrapper pt-16 pb-32">
          <div className="row">
            <div className="col-12 md:col-4 lg:col-3 lg:offset-1 mb-8">
              <h2 className="type-660 md:type-760">
                { code === "jp" ? sanityContactPage.contentTitle.jp : sanityContactPage.contentTitle.en }
              </h2>
            </div>
            <div className="col-12 md:col-7 md:offset-1 lg:col-6">
              <RichText localeBlocks={sanityContactPage.paragraph} lang={code} />
              <ButtonPrimary to={sanityContactPage.email} className="inline-block mt-12">
                { code === "jp" ? "メールを送る" : "Send email" }
              </ButtonPrimary>
            </div>
          </div>
        </div>
      </div>
    </Layout>
  )
}

Contact.propTypes = {
  data: PropTypes.shape({
    sanityContactPage: PropTypes.shape({
      contentTitle: PropTypes.object,
      title: PropTypes.object,
      paragraph: PropTypes.object,
      email: PropTypes.string,
    }),
  })
}

Contact.defaultProps = {
  data: {
    sanityHomePage: {}
  }
}

export default localize(Contact)
